import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';

import { Heading, LayoutSingleColumn, Page } from '../../components';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import { useConfiguration } from '../../context/configurationContext';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { isMainSearchTypeKeywords } from '../../util/search';
import css from './NotFoundPage.module.css';

export class NotFoundPageComponent extends Component {
  constructor(props) {
    super(props);
    // The StaticRouter component used in server side rendering
    // provides the context object. We attach a `notfound` flag to
    // the context to tell the server to change the response status
    // code into a 404.
    // @ts-expect-error TS(2339) FIXME: Property 'staticContext' does not exist on type 'R... Remove this comment to see the full error message
    this.props.staticContext.notfound = true;
  }

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'marketplaceName' does not exist on type ... Remove this comment to see the full error message
      marketplaceName,
      // @ts-expect-error TS(2339) FIXME: Property 'intl' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      intl,
      // @ts-expect-error TS(2339) FIXME: Property 'scrollingDisabled' does not exist on typ... Remove this comment to see the full error message
      scrollingDisabled,
    } = this.props;

    const title = intl.formatMessage({
      id: 'NotFoundPage.title',
    });

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn topbar={<TopbarContainer />}>
          <div className={css.root}>
            <div className={css.content}>
              <div className={css.number}>404</div>
              <Heading as="h1" rootClassName={css.heading}>
                <FormattedMessage id="NotFoundPage.heading" />
              </Heading>
              <p className={css.description}>
                <FormattedMessage id="NotFoundPage.description" values={{ marketplaceName }} />
              </p>
            </div>
          </div>
          <FooterContainer />
        </LayoutSingleColumn>
      </Page>
    );
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
NotFoundPageComponent.defaultProps = {
  staticContext: {},
};

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
NotFoundPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  marketplaceName: string.isRequired,
  isKeywordSearch: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from useIntl
  intl: intlShape.isRequired,
};

const EnhancedNotFoundPage = props => {
  const config = useConfiguration();
  const intl = useIntl();

  return (
    <NotFoundPageComponent
      marketplaceName={config.marketplaceName}
      isKeywordSearch={isMainSearchTypeKeywords(config)}
      intl={intl}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const NotFoundPage = compose(connect(mapStateToProps))(EnhancedNotFoundPage);

export default NotFoundPage;
