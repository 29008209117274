import { useSelector } from 'react-redux';

import { ensureCurrentUser } from '../../util/data';

export const useCurrentUser = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  const currentUser = useSelector(state => ensureCurrentUser(state.user.currentUser));
  // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  const isLoading = useSelector(state => state.user.isLoading);

  return { currentUser, isLoading };
};

export const useCurrentUserId = () => {
  return useSelector((state: any) => state.user.currentUser?.id?.uuid as string | undefined);
};
