import { GetListingsWithFiltersRequest } from 'containers/SearchPage/SearchPage.hooks';

export type CustomData = {
  title: string;
  brand: string;
  brandId: string;
  initialType: string;
  productType: string;
  productSubtype: string;
  productCategory: string;
  sectionDescription?: string;
  pageCopy?: string;
  image1Alt: string;
  image2Alt: string;
  image3Alt: string;
  shopButtonTitle: string;
};

export function getSEOPagesListingQueryParams(customData: CustomData) {
  const queryParams: GetListingsWithFiltersRequest = {
    brandIds:
      (customData?.brandId && customData?.brandId?.split(',').map(id => parseInt(id, 10))) ||
      undefined,
    productType: customData?.productType ? [customData?.productType] : undefined,
    productSubtype: customData?.productSubtype ? [customData?.productSubtype] : undefined,
    category: customData?.productCategory || undefined,
  };

  return queryParams;
}
