import { useMutation, useQuery } from '@tanstack/react-query';

import { get, post } from 'util/httpsClient';

export interface SubscribeToNewsletterParams {
  email: string;
  firstName?: string;
  lastName?: string;
}

export const useSubscribeToNewsletter = () => {
  return useMutation({
    mutationFn: ({ email, firstName, lastName }: SubscribeToNewsletterParams) => {
      return post({ path: '/subscribe', body: { email, firstName, lastName } });
    },
    meta: { name: 'subscribeToNewsletter' },
  });
};

export const useIsSubscribedToNewsletter = (email: string, enabled?: boolean) => {
  return useQuery({
    queryKey: ['isSubscribedToNewsletter', email],
    queryFn: () =>
      get({ path: `/is-subscribed?email=${email}` }).then(res => Boolean(res.isSubscribed)),
    enabled: !!email && enabled,
  });
};
