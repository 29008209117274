import dayjs, { ManipulateType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

/**
 * Check if the browser's DateTimeFormat API supports time zones.
 *
 * @returns {Boolean} true if the browser returns current time zone.
 */
export const isTimeZoneSupported = () => {
  if (!Intl || typeof Intl === 'undefined' || typeof Intl.DateTimeFormat === 'undefined') {
    return false;
  }

  const dtf = new Intl.DateTimeFormat();
  if (typeof dtf === 'undefined' || typeof dtf.resolvedOptions === 'undefined') {
    return false;
  }
  return !!dtf.resolvedOptions().timeZone;
};

/**
 * Detect the default timezone of user's browser.
 * This function can only be called from client side.
 * I.e. server-side rendering doesn't make sense - it would not return user's timezone.
 *
 * @returns {String} string containing IANA timezone key (e.g. 'Europe/Helsinki')
 */
export const getDefaultTimeZoneOnBrowser = () => {
  if (typeof window === 'undefined') {
    throw new Error(
      'Utility function: getDefaultTimeZoneOnBrowser() should be called on client-side only.'
    );
  }

  if (isTimeZoneSupported()) {
    const dtf = new Intl.DateTimeFormat();
    const currentTimeZone = dtf.resolvedOptions().timeZone;
    if (currentTimeZone) {
      return currentTimeZone;
    }
  }

  console.error(
    'Utility function: getDefaultTimeZoneOnBrowser() was not able to detect time zone.'
  );
  return 'Etc/UTC';
};

/**
 * Check if the given time zone key is valid.
 *
 * @param {String} timeZone time zone id, see:
 *   https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 *
 * @returns {Boolean} true if the browser recognizes the key.
 */
export const isValidTimeZone = timeZone => {
  try {
    new Intl.DateTimeFormat('en-US', { timeZone }).format();
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Return the names of the time zones according to IANA timezone db.
 *
 * @param {RegExp} relevantZonesRegExp is pattern to filter returned time zones.
 *
 * @returns {Array} an array of relevant time zones.
 */
export const getTimeZoneNames = relevantZonesRegExp => {
  const allTimeZones = (Intl as any).supportedValuesOf('timeZone');
  return relevantZonesRegExp ? allTimeZones.filter(z => relevantZonesRegExp.test(z)) : allTimeZones;
};

/**
 * Check that the given parameter is a Date object.
 *
 * @param {Date} object that should be a Date.
 *
 * @returns {boolean} true if given parameter is a Date object.
 */
export const isDate = d =>
  d && Object.prototype.toString.call(d) === '[object Date]' && !Number.isNaN(d.getTime());

/**
 * Check if the given parameters represent the same Date value (timestamps are compared)
 *
 * @param {Date} first param that should be a Date and it should have same timestamp as second param.
 * @param {Date} second param that should be a Date and it should have same timestamp as second param.
 *
 * @returns {boolean} true if given parameters have the same timestamp.
 */
export const isSameDate = (a, b) => a && isDate(a) && b && isDate(b) && a.getTime() === b.getTime();

/**
 * Compare is dateA is after dateB
 *
 * @param {Date} dateA date instance
 * @param {Date} dateB date instance
 *
 * @returns {Date} true if dateA is after dateB
 */
export const isAfterDate = (dateA, dateB) => {
  return dayjs(dateA).isAfter(dayjs(dateB));
};

/**
 * Compare is dateA is after dateB
 *
 * @param {Date} dateA date instance
 * @param {Date} dateB date instance
 *
 * @returns {Date} true if dateA is after dateB
 */
export const isDateSameOrAfter = (dateA, dateB) => {
  return dayjs(dateA).isSameOrAfter(dayjs(dateB));
};

/**
 * Check that the given dates are pointing to the same day.
 *
 * @param {Date} date1 first date object
 * @param {Date} date2 second date object
 * @param {String} timeZone (if omitted local time zone is used)
 *
 * @returns {boolean} true if Date objects are pointing to the same day on given time zone.
 */
export const isSameDay = (date1, date2, timeZone) => {
  const d1 = timeZone ? dayjs(date1).tz(timeZone) : dayjs(date1);
  const d2 = timeZone ? dayjs(date2).tz(timeZone) : dayjs(date2);
  return d1.isSame(d2, 'day');
};

/**
 * Check if the date is in the given range, start and end included.
 * @param {Date} date to be checked
 * @param {Date} start start of the range
 * @param {Date} end end of the range
 * @param {string} timeUnit scope of the range, e.g. 'day', 'hour', 'minute', can be also null
 * @param {String} timeZone
 *
 * @returns {boolean} is date in range
 */
export const isInRange = (date, start, end, timeUnit, timeZone) => {
  const dateMoment = timeZone ? dayjs(date).tz(timeZone) : dayjs(date);
  // Range usually ends with 00:00, and with day timeUnit,
  // this means that exclusive end is wrongly taken into range.
  // Note about timeUnit with isBetween: in the event that the from and to parameters are the same,
  // but the inclusivity parameters are different, false will preside.
  // aka moment('2016-10-30').isBetween('2016-10-30', '2016-10-30', undefined, '(]'); //false
  // => we need to use []
  const millisecondBeforeEndTime = new Date(end.getTime() - 1);
  return dateMoment.isBetween(start, millisecondBeforeEndTime, timeUnit, '[]');
};

/**
 * Convert timestamp to date
 * @param {string} timestamp
 *
 * @returns {Date} timestamp converted to date
 */
export const timestampToDate = timestamp => {
  return new Date(Number.parseInt(timestamp, 10));
};

////////////////////////////////////////////////////////////////////
// Manipulate time: time-of-day between different time zones etc. //
////////////////////////////////////////////////////////////////////

/**
 * Returns a new date, which indicates the same time of day in a given time zone
 * as given date is in local time zone
 *
 * @param {Date} date
 * @param {String} timeZone
 *
 * @returns {Date} date in given time zone
 */
export const timeOfDayFromLocalToTimeZone = (date, timeZone) => {
  return dayjs.tz(dayjs(date).format('YYYY-MM-DD HH:mm:ss'), timeZone).toDate();
};

/**
 * Returns a new date, which indicates the same time of day in a local time zone
 * as given date is in specified time zone
 *
 * @param {Date} date
 * @param {String} timeZone
 *
 * @returns {Date} date in given time zone
 */
export const timeOfDayFromTimeZoneToLocal = (date, timeZone) => {
  return dayjs(dayjs(date).tz(timeZone).format('YYYY-MM-DD HH:mm:ss')).toDate();
};

/**
 * Get start of time unit (e.g. start of day)
 *
 * @param {Date} date date instance to be converted
 * @param {String} unit time-unit (e.g. "day")
 * @param {String} timeZone time zone id
 *
 * @returns {Date} date object converted to the start of given unit
 */
export const getStartOf = (date, unit, timeZone, offset = 0, offsetUnit = 'days') => {
  const m = timeZone ? dayjs(date).clone().tz(timeZone) : dayjs(date).clone();

  const startOfUnit = m.startOf(unit);
  const startOfUnitWithOffset =
    offset === 0 ? startOfUnit : startOfUnit.add(offset, offsetUnit as ManipulateType);
  return startOfUnitWithOffset.toDate();
};

/**
 * Adds time-units to the date
 *
 * @param {Date} date date to be manipulated
 * @param {int} offset offset of time-units (e.g. "3" days)
 * @param {String} unit time-unit (e.g. "days")
 * @param {String} timeZone time zone name
 *
 * @returns {Date} date with given offset added
 */
export const addTime = (date, offset, unit, timeZone) => {
  const m = timeZone ? dayjs(date).clone().tz(timeZone) : dayjs(date).clone();
  return m.add(offset, unit).toDate();
};

/**
 * Subtract time-units from the date
 *
 * @param {Date} date date to be manipulated
 * @param {int} offset offset of time-units (e.g. "3" days)
 * @param {String} unit time-unit (e.g. "days")
 * @param {String} timeZone time zone name
 *
 * @returns {Date} date with given offset subtracted
 */
export const subtractTime = (date, offset, unit, timeZone) => {
  const m = timeZone ? dayjs(date).clone().tz(timeZone) : dayjs(date).clone();
  return m.subtract(offset, unit).toDate();
};

///////////////
// Durations //
///////////////

/**
 * Calculate the number of days between the given dates.
 * This uses moment#diff and, therefore, it just checks,
 * if there are 1000x60x60x24 milliseconds between date objects.
 *
 * Note: This should not be used for checking if the local date has
 *       changed between "2021-04-07 23:00" and "2021-04-08 05:00".
 *
 * @param {Date} startDate start of the time period
 * @param {Date} endDate end of the time period. NOTE: with daily
 * bookings, it is expected that this date is the exclusive end date,
 * i.e. the last day of the booking is the previous date of this end
 * date.
 *
 * @throws Will throw if the end date is before the start date
 * @returns {Number} number of days between the given dates
 */
export const daysBetween = (startDate, endDate) => {
  const days = dayjs(endDate).diff(startDate, 'days');
  if (days < 0) {
    throw new Error('End date cannot be before start date');
  }
  return days;
};

/**
 * Count the number of minutes between the given Date objects.
 *
 * @param {Date} startDate start of the time period
 * @param {Date} endDate end of the time period.
 *
 * @returns {Number} number of minutes between the given Date objects
 */
export const minutesBetween = (startDate, endDate) => {
  const minutes = dayjs(endDate).diff(startDate, 'minutes');
  return minutes;
};

/**
 * Calculate the difference between the given dates
 *
 * @param {Date} startDate start of the time period
 * @param {Date} endDate end of the time period.
 * @param {String} unit time unit. E.g. 'years'.
 * @param {String} useFloat Should return floating point numbers?
 *
 * @returns {Number} time difference between the given Date objects using given unit
 */
export const diffInTime = (startDate, endDate, unit, useFloat = false) => {
  return dayjs(startDate).diff(endDate, unit, useFloat);
};

////////////////////////////
// Parsing and formatting //
////////////////////////////

const getTimeZoneMaybe = timeZone => {
  if (timeZone) {
    if (!isTimeZoneSupported()) {
      throw new Error(`Your browser doesn't support time zones.`);
    }

    if (!isValidTimeZone(timeZone)) {
      throw new Error(`Given time zone key (${timeZone}) is not valid.`);
    }
    return { timeZone };
  }
  return {};
};

/**
 * Format the given date. Printed string depends on how close the date is the current day.
 * E.g. "Today, 9:10 PM", "Sun 6:02 PM", "Jul 20, 6:02 PM", "Jul 20 2020, 6:02 PM"
 *
 * @param {Date} date Date to be formatted
 * @param {Object} intl Intl object from react-intl
 * @param {String} todayString translation for the current day
 * @param {Object} [opts] options. Can be used to pass in timeZone. It should represent IANA time zone key.
 *
 * @returns {String} formatted date
 */
export const formatDateWithProximity = (date, intl, todayString, opts = {}) => {
  const paramsValid = intl && date instanceof Date && typeof todayString === 'string';
  if (!paramsValid) {
    throw new Error(`Invalid params for formatDate: (${date}, ${intl}, ${todayString})`);
  }

  // If timeZone parameter is set, use it as formatting option
  // @ts-expect-error TS(2339) FIXME: Property 'timeZone' does not exist on type '{}'.
  const { timeZone } = opts;
  const timeZoneMaybe = getTimeZoneMaybe(timeZone);

  // By default we can use moment() directly but in tests we need to use a specific dates.
  // Tests inject now() function to intl wich returns predefined date
  const now = intl.now ? dayjs(intl.now()) : dayjs();

  // isSame: if the two moments have different time zones, the time zone of the first moment will be used for the comparison.
  const localizedNow = timeZoneMaybe.timeZone ? now.tz(timeZone) : now;

  if (localizedNow.isSame(date, 'day')) {
    // e.g. "Today, 9:10 PM"
    const formattedTime = intl.formatDate(date, {
      hour: 'numeric',
      minute: 'numeric',
      ...timeZoneMaybe,
    });
    return `${todayString}, ${formattedTime}`;
  } else if (localizedNow.isSame(date, 'week')) {
    // e.g.
    // en-US: "Sun 6:02 PM"
    // en-GB: "Sun 18:02"
    // fr-FR: "dim. 18:02"
    return intl.formatDate(date, {
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      ...timeZoneMaybe,
    });
  } else if (localizedNow.isSame(date, 'year')) {
    // e.g.
    // en-US: "Jul 20, 6:02 PM"
    // en-GB: "20 Jul, 18:02"
    // fr-FR: "20 juil., 18:02"
    return intl.formatDate(date, {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      ...timeZoneMaybe,
    });
  } else {
    // e.g.
    // en-US: "Jul 20, 2020, 6:02 PM"
    // en-GB: "20 Jul 2020, 18:02"
    // fr-FR: "20 juil. 2020, 18:02"
    return intl.formatDate(date, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      ...timeZoneMaybe,
    });
  }
};

/**
 * Formats date to into multiple different ways:
 * - date "Mar 24"
 * - time "8:07 PM"
 * - dateAndTime: "Mar 24, 8:07 PM"
 *
 * If date is on different year, it will show it.
 *
 * @param {Date} date to be formatted
 * @param {Object} intl Intl object from react-intl
 * @param {Object} [opts] options. Can be used to pass in timeZone. It should represent IANA time zone key.
 *
 * @returns {Object} "{ date, time, dateAndTime }"
 */
export const formatDateIntoPartials = (date, intl, opts = {}) => {
  // If timeZone parameter is set, use it as formatting option
  // @ts-expect-error TS(2339) FIXME: Property 'timeZone' does not exist on type '{}'.
  const { timeZone } = opts;
  const timeZoneMaybe = getTimeZoneMaybe(timeZone);

  // By default we can use moment() directly but in tests we need to use a specific dates.
  // Tests inject now() function to intl wich returns predefined date
  const now = intl.now ? dayjs(intl.now()) : dayjs();

  // isSame: if the two moments have different time zones, the time zone of the first moment will be used for the comparison.
  const localizedNow = timeZoneMaybe.timeZone ? now.tz(timeZone) : now;
  const yearMaybe = localizedNow.isSame(date, 'year') ? {} : { year: 'numeric' };

  return {
    date: intl.formatDate(date, {
      month: 'short',
      day: 'numeric',
      ...yearMaybe,
      ...timeZoneMaybe,
    }),
    time: intl.formatDate(date, {
      hour: 'numeric',
      minute: 'numeric',
      ...timeZoneMaybe,
    }),
    dateAndTime: intl.formatDate(date, {
      ...yearMaybe,
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      ...timeZoneMaybe,
    }),
  };
};

/**
 * Parses given date string in ISO8601 format('YYYY-MM-DD') to date in
 * the given time zone.
 *
 * This is used in search when filtering by time-based availability.
 *
 * Example:
 * ('2020-04-15', 'Etc/UTC') => new Date('2020-04-15T00:00:00.000Z')
 * ('2020-04-15', 'Europe/Helsinki') => new Date('2020-04-14T21:00:00.000Z')
 *
 * @param {String} dateString in 'YYYY-MM-DD' format
 * @param {String} [timeZone] time zone id, see:
 *   https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 *
 * @returns {Date} date
 */
export const parseDateFromISO8601 = (dateString, timeZone = null) => {
  return timeZone
    ? dayjs.tz(dateString, timeZone).toDate()
    : dayjs(dateString, 'YYYY-MM-DD').toDate();
};

/**
 * Parses a date string that has format like "YYYY-MM-DD HH:mm" into localized date
 * @param {String} dateTimeString
 * @param {String} timeZone time zone id, see:
 *   https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 * @returns returns Date object
 */
export const parseDateTimeString = (dateTimeString, timeZone = null) => {
  return timeZone
    ? dayjs.tz(dateTimeString, timeZone).toDate()
    : dayjs(dateTimeString, 'YYYY-MM-DD HH:mm').toDate();
};

/**
 * Converts date to string ISO8601 format ('YYYY-MM-DD').
 * This string is used e.g. in urlParam.
 *
 * @param {Date} date
 * @param {String} [timeZone] time zone id, see:
 *   https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 *
 * @returns {String} string in 'YYYY-MM-DD' format
 */
export const stringifyDateToISO8601 = (date, timeZone = null) => {
  return timeZone
    ? dayjs(date).tz(timeZone).format('YYYY-MM-DD')
    : dayjs(date).format('YYYY-MM-DD');
};

///////////////////////
// Time unit helpers //
///////////////////////

// NOTE: If your customization is using different time-units than hours
// and different boundaries than sharp hours, you need to modify these functions:
// - findBookingUnitBoundaries (DST changes)
// - findNextBoundary
// - getSharpHours
// - getStartHours
// - getEndHours

// Helper function for exported function: getSharpHours
// Recursively find boundaries for bookable time slots.
const findBookingUnitBoundaries = params => {
  const {
    cumulatedResults,
    currentBoundary,
    startMoment,
    endMoment,
    nextBoundaryFn,
    intl,
    timeZone,
    timeUnit = 'hour',
  } = params;

  if (dayjs(currentBoundary).isBetween(startMoment, endMoment, null, '[]')) {
    const timeOfDay = formatDateIntoPartials(currentBoundary, intl, { timeZone })?.time;

    // Choose the previous (aka first) sharp hour boundary,
    // if daylight saving time (DST) creates the same time of day two times.
    const newBoundary =
      cumulatedResults &&
      cumulatedResults.length > 0 &&
      cumulatedResults.slice(-1)[0].timeOfDay === timeOfDay
        ? []
        : [
            {
              timestamp: currentBoundary.valueOf(),
              timeOfDay,
            },
          ];

    return findBookingUnitBoundaries({
      ...params,
      cumulatedResults: [...cumulatedResults, ...newBoundary],
      currentBoundary: dayjs(nextBoundaryFn(currentBoundary, timeUnit, timeZone)),
    });
  }
  return cumulatedResults;
};

/**
 * Find the next sharp hour after the current moment.
 *
 * @param {Moment|Date} Start point for looking next sharp hour.
 * @param {String} timeUnit scope. e.g. 'hour', 'day'
 * @param {String} timezone name. It should represent IANA timezone key.
 *
 * @returns {Array} an array of localized hours.
 */
export const findNextBoundary = (currentMomentOrDate, timeUnit, timeZone) =>
  dayjs(currentMomentOrDate).clone().tz(timeZone).add(1, timeUnit).startOf(timeUnit).toDate();

//////////
// Misc //
//////////

/**
 * Format the given date to month id/string: 'YYYY-MM'.
 *
 * @param {Date} date to be formatted
 * @param {String} [timeZone] time zone name (optional parameter).
 *
 * @returns {String} formatted month string
 */
export const monthIdString = (date, timeZone = null) => {
  return timeZone ? dayjs(date).tz(timeZone).format('YYYY-MM') : dayjs(date).format('YYYY-MM');
};

/**
 * Get the day (number) of the week (similar to "new Date().getDay()")
 * @param {Date} date
 * @param {String} timeZone name. It should represent IANA timezone key.
 * @returns the day of week number 0(Sun) ... 6(Sat)
 */
export const getDayOfWeek = (date, timeZone) => {
  return timeZone ? dayjs(date).tz(timeZone).day() : dayjs(date).day();
};

/**
 * Get the start of a week as a Momemnt instance.
 * This is used by react-dates library (e.g. WeeklyCalendar)
 * @param {Moment} dayMoment moment instance representing a day
 * @param {String} timeZone name. It should represent IANA timezone key.
 * @param {number} firstDayOfWeek (which weekday is the first day?)
 * @returns return moment object representing the first moment of the week where dayMoment belongs to
 */
export const getStartOfWeekAsMoment = (dayMoment, timeZone, firstDayOfWeek) => {
  const m = timeZone ? dayMoment.clone().tz(timeZone) : dayMoment.clone();
  let d = m.startOf('day');
  const diffToSunday = d.day();
  const adjustOffset =
    diffToSunday === 0 && firstDayOfWeek > 0 ? -7 + firstDayOfWeek : firstDayOfWeek;
  const startOfWeek = d.date() - diffToSunday + adjustOffset; // adjust when day is sunday
  return d.clone().date(startOfWeek);
};

/**
 * Get the start of a week as a Date instance.
 * This is used by react-dates library (e.g. WeeklyCalendar)
 * @param {Date} date instance
 * @param {String} timeZone name. It should represent IANA timezone key.
 * @param {number} firstDayOfWeek (which weekday is the first day?)
 * @returns a Date object representing the first day of the week where given date belongs to
 */
export const getStartOfWeek = (date, timeZone, firstDayOfWeek) => {
  return getStartOfWeekAsMoment(dayjs(date).tz(timeZone), timeZone, firstDayOfWeek).toDate();
};

export const parseDate = dateString => {
  const parts = dateString.split('/');

  return new Date(parts[2], parts[1] - 1, parts[0]);
};

export const formatDate = dateString => {
  const date = parseDate(dateString);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
};
