import { Listing, SharetribeListing } from 'models/ListingModels';
import { UpdateListingRequest } from 'models/ListingSchemas';
import { newMoney } from 'util/currency';
import { omitUndefined } from 'util/httpUtils';
import { Image, toImageObject } from 'util/images';

export const mapDraftSharetribeToDomainListing = (listing: SharetribeListing): Partial<Listing> => {
  return {
    id: listing.id.uuid,
    brandId: listing.attributes.publicData?.brandId
      ? parseInt(listing.attributes.publicData.brandId, 10)
      : undefined,
    originalPhotoIds: listing.attributes.publicData?.originalPhotoIds || [],
    removedBgPhotoIds: listing.attributes.publicData?.removedBgPhotoIds || [],
    images: listing.relationships?.images?.data?.map(image => image.id.uuid),
  };
};

export const mapUpdateListingRequestToSharetribe = (listing: UpdateListingRequest): any => {
  const price = newMoney(listing.price, listing.currency);

  const publicData = omitUndefined({
    description: listing.description,
    colors: listing.colors,
    brandId: listing.brandId ? listing.brandId.toString() : undefined,
    partnerId: listing.partnerId ? listing.partnerId.toString() : undefined,
    priceWhenBought: listing.priceWhenBought
      ? {
          amount: listing.priceWhenBought,
          currency: listing.priceWhenBoughtCurrency,
        }
      : undefined,
    category: listing.category,
    department: listing.department,
    productSubtype: listing.productSubtype,
    productType: listing.productType,
    originalPhotoIds: listing.originalPhotoIds,
    removedBgPhotoIds: listing.removedBgPhotoIds,
    shipsTo: listing.shipsTo,
    normalizedSizes: listing.normalizedSizes,
    condition: listing.condition,
    conditionDetails: listing.conditionDetails,
    size: listing.size,
    sizeStandard: listing.sizeStandard,
    shippingFromAddressId: listing.shippingFromAddressId
      ? listing.shippingFromAddressId.toString()
      : undefined,
  });

  return {
    id: {
      uuid: listing.id,
      _sdkType: 'UUID',
    },
    ...(listing.title !== undefined && { title: listing.title }),
    publicData,
    ...(listing.price !== undefined && { price }),
  };
};

export const mapListingResponseToListing = (listing: any): Listing => {
  return {
    ...listing,
    images: listing?.images?.map(imgId => toImageObject(imgId)) || [],
  };
};

export const mapSharetribeListingToListing = (listing: SharetribeListing): Partial<Listing> => {
  return {
    id: listing.id.uuid,
    title: listing.attributes.title || '',
    description: listing.attributes.description || '',
    state: listing.attributes.state || '',
    price: listing.attributes.price?.amount || 0,
    currency: listing.attributes.price?.currency || '',
    tags: listing.attributes.publicData?.tags || [],
    department: listing.attributes.publicData?.department || '',
    category: listing.attributes.publicData?.category || '',
    condition: listing.attributes.publicData?.condition || '',
    colors: listing.attributes.publicData?.colors || [],
    size: listing.attributes.publicData?.size || '',
    authorId: listing.attributes.publicData?.authorId || '',
    brandId: listing.attributes.publicData?.brandId || 0,
    brand: listing.attributes.publicData?.brand || {},
    normalizedSizes: listing.attributes.publicData?.normalizedSizes || [],
    originalPhotoIds: listing.attributes.publicData?.originalPhotoIds || [],
    removedBgPhotoIds: listing.attributes.publicData?.removedBgPhotoIds || [],
    shipsTo: listing.attributes.publicData?.shipsTo || [],
    author: listing.relationships?.author?.data?.id.uuid || '',
    images: listing.relationships?.images?.data?.map(image => image.id.uuid),
  };
};
