/**
 * This component wraps React-Router's Link by providing name-based routing.
 *
 * The `name` prop should match a route in the flattened
 * routeConfiguration passed in context by the RoutesProvider
 * component. The `params` props is the route params for the route
 * path of the given route name.
 *
 * The `to` prop is an object with the same shape as Link requires,
 * but without `pathname` that will be generated from the given route
 * name.
 *
 * Some additional props can be passed for the <a> element like
 * `className` and `style`.
 *
 * The component can also be given the `activeClassName` prop that
 * will be added to the element className if the current URL matches
 * the one in the generated pathname of the link.
 */
import { LoadableComponent } from '@loadable/component';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { getPath, getRoute } from '../../util/routes';
import { RouteName } from 'routing/routeConfiguration';

type NamedLinkProps = PropsWithChildren<{
  name: RouteName;
  params?: object;
  to?: {
    search?: string;
    hash?: string;
    state?: object;
  };
  className?: string;
  style?: React.CSSProperties;
  activeClassName?: string;
  title?: string;
  match?: object;
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  tabIndex?: number;
}>;

export const NamedLink = React.forwardRef<HTMLAnchorElement, NamedLinkProps>((props, ref) => {
  const {
    name,
    params = {},
    title,
    active: activeProp,
    to = {},
    children,
    className,
    style,
    activeClassName,
    onClick,
    tabIndex,
    ...rest
  } = props;

  const onOver = () => {
    const { component: Page } = getRoute(name);
    // Loadable Component has a "preload" function.
    (Page as LoadableComponent<any>).preload?.();
  };

  // Link props
  const pathname = getPath(name, params);
  const match = useRouteMatch();
  const active = match.url && match.url === pathname;

  // <a> element props
  const aElemProps = {
    className: classNames(className, activeClassName && { [activeClassName]: active }),
    style,
    title,
    ...rest,
  };

  return (
    <Link
      onClick={onClick}
      onMouseOver={onOver}
      onTouchStart={onOver}
      to={{ pathname, ...to }}
      tabIndex={tabIndex}
      {...aElemProps}
      data-active={activeProp ?? active}
      ref={ref}
    >
      {children}
    </Link>
  );
});

export default NamedLink;
