import classNames from 'classnames';
import React from 'react';
import { range } from 'remeda';

import { IconChevronLeft, IconChevronRight } from '../../assets/icons';
import { NamedLink } from '../../components';
import { useIntl } from '../../util/reactIntl';
import { stringify } from '../../util/urlHelpers';
import css from './PaginationLinks.module.css';
import { useQueryParams } from 'hooks/useQueryParams';
import { RouteName } from 'routing/routeConfiguration';

let pgKey = 0;
const paginationGapKey = () => {
  pgKey += 1;
  return pgKey;
};

/**
 * Returns an array containing page numbers and possible ellipsis '…' characters.
 *
 * @param {Number} page - current page
 * @param {Number} totalPages - total page count
 * @return {Array}
 */
const getPageNumbersArray = (page, totalPages) => {
  // Create array of numbers: [1, 2, 3, 4, ..., totalPages]
  const numbersFrom1ToTotalPages = range(1, totalPages + 1);
  return numbersFrom1ToTotalPages
    .filter(v => {
      // Filter numbers that are next to current page and pick also first and last page
      // E.g. [1, 4, 5, 6, 9], where current page = 5 and totalPages = 9.
      return v === 1 || Math.abs(v - page) <= 1 || v === totalPages;
    })
    .reduce((newArray, p) => {
      // Create a new array where gaps between consecutive numbers is filled with ellipsis character
      // E.g. [1, '…', 4, 5, 6, '…', 9], where current page = 5 and totalPages = 9.
      const isFirstPageOrNextToCurrentPage = p === 1 || newArray[newArray.length - 1] + 1 === p;
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      return isFirstPageOrNextToCurrentPage ? newArray.concat([p]) : newArray.concat(['\u2026', p]);
    }, []);
};

type PaginationLinkProps = {
  className?: string;
  rootClassName?: string;
  pageName: RouteName;
  pagePathParams?: object;
  totalPages: number;
};

export const PaginationLinksComponent: React.FC<PaginationLinkProps> = props => {
  const { pageName, pagePathParams, totalPages } = props;
  const intl = useIntl();

  const [{ page, ...rest }] = useQueryParams({
    page: 1,
  });

  const prevPage = page > 1 ? page - 1 : null;
  const nextPage = page < totalPages ? page + 1 : null;
  const prevSearchParams = { ...rest, page: prevPage };
  const nextSearchParams = { ...rest, page: nextPage };

  /* Arrow links: to previous page */
  const prevLinkEnabled = (
    <NamedLink
      className={classNames(css.toPageLink, css.prev)}
      name={pageName}
      params={pagePathParams}
      to={{ search: stringify(prevSearchParams) }}
      title={intl.formatMessage({ id: 'PaginationLinks.previous' })}
    >
      <IconChevronLeft />
    </NamedLink>
  );

  /* Arrow links: to next page */
  const nextLinkEnabled = (
    <NamedLink
      className={classNames(css.toPageLink, css.next)}
      name={pageName}
      params={pagePathParams}
      to={{ search: stringify(nextSearchParams) }}
      title={intl.formatMessage({ id: 'PaginationLinks.next' })}
    >
      <IconChevronRight />
    </NamedLink>
  );

  /* Numbered pagination links */

  const pageNumbersNavLinks = getPageNumbersArray(page, totalPages).map(v => {
    const isCurrentPage = v === page;
    const pageClassNames = classNames(css.toPageLink, { [css.currentPage]: isCurrentPage });

    return typeof v === 'number' ? (
      <NamedLink
        key={v}
        className={pageClassNames}
        name={pageName}
        params={pagePathParams}
        to={{ search: stringify({ ...rest, page: v }) }}
        title={intl.formatMessage({ id: 'PaginationLinks.toPage' }, { page: v })}
      >
        {v}
      </NamedLink>
    ) : (
      <span key={`pagination_gap_${paginationGapKey()}`} className={css.paginationGap}>
        {v}
      </span>
    );
  });

  // Using 'justify-content: space-between' we can deal with very narrow mobile screens.
  // However, since the length of pageNumberList can vary up to 7,
  // we need to keep track of how much space is allocated for the list
  // Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
  const pageNumberListClassNames = classNames(
    css.pageNumberList,
    css[`pageNumberList${pageNumbersNavLinks.length}Items`]
  );

  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <nav className={classes}>
      {prevPage ? prevLinkEnabled : null}
      <div className={pageNumberListClassNames}>{pageNumbersNavLinks}</div>
      {nextPage ? nextLinkEnabled : null}
    </nav>
  );
};

export default PaginationLinksComponent;
