import { useQuery } from '@tanstack/react-query';

import * as log from '../util/log';
import { IP_API_URL } from 'util/constants';

interface UserCountryData {
  country: string;
  lastFetched: number;
}

export const useGeolocation = () => {
  const {
    data: geolocation,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ['geolocation'],
    queryFn: fetchUserGeolocation,
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
  });

  const updateGeolocation = countryCode => {
    updateUserGeolocation(countryCode);
    refetch();
  };

  return {
    userGeolocation: geolocation?.country || 'GB',
    isLoading,
    error,
    updateGeolocation,
  };
};

export const updateUserGeolocation = countryCode => {
  const newData = {
    country: countryCode,
    lastFetched: Date.now(),
  };
  localStorage.setItem('userGeolocation', JSON.stringify(newData));
  return newData;
};

const fetchUserGeolocation = async (): Promise<UserCountryData> => {
  try {
    const cachedData = localStorage.getItem('userGeolocation');
    if (cachedData) {
      const parsedData: UserCountryData = JSON.parse(cachedData);
      if (Date.now() - parsedData.lastFetched <= 24 * 60 * 60 * 1000) {
        // 24 hours
        return parsedData;
      }
    }

    const response = await fetch(IP_API_URL);
    const data = await response.json();

    if (data.country) {
      const newData: UserCountryData = {
        country: data.country,
        lastFetched: Date.now(),
      };
      localStorage.setItem('userGeolocation', JSON.stringify(newData));
      return newData;
    } else {
      throw new Error('Failed to fetch user country');
    }
  } catch (error) {
    log.error(error, 'fetch-geolocation-failed', error);
    return {
      country: 'GB',
      lastFetched: Date.now(),
    };
  }
};
