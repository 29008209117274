import { LoadableComponent } from '@loadable/component';
import classNames from 'classnames';
import { ButtonHTMLAttributes, useEffect, useState } from 'react';
import React from 'react';

import { IconSpinner } from '../../components';
import { getRoute } from '../../util/routes';
import css from './Button.module.css';
import { IconCheck } from 'assets/icons';

const PlainButton = React.forwardRef((props: any, ref) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const {
    children,
    className,
    rootClassName,
    spinnerClassName,
    checkmarkClassName,
    inProgress,
    ready,
    disabled,
    enforcePagePreloadFor,
    ...rest
  } = props;

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className, {
    [css.ready]: ready,
    [css.inProgress]: inProgress,
  });

  let content;

  if (inProgress) {
    content = (
      <>
        <span style={{ visibility: 'hidden' }}>{children}</span>
        <IconSpinner
          rootClassName={spinnerClassName || css.spinner}
          style={{ position: 'absolute' }}
        />
      </>
    );
  } else if (ready) {
    content = (
      <>
        <span style={{ visibility: 'hidden' }}>{children}</span>
        <IconCheck
          className={checkmarkClassName || css.checkmark}
          style={{ position: 'absolute' }}
        />
      </>
    );
  } else {
    content = children;
  }

  const onOverButtonFn = enforcePreloadOfPage => () => {
    // Enforce preloading of given page (loadable component)
    const { component: Page } = getRoute(enforcePreloadOfPage);

    (Page as LoadableComponent<any>).preload?.();
  };

  const onOverButton = enforcePagePreloadFor ? onOverButtonFn(enforcePagePreloadFor) : null;
  const onOverButtonMaybe = onOverButton
    ? {
        onMouseOver: onOverButton,
        onTouchStart: onOverButton,
      }
    : {};

  // All buttons are disabled until the component is mounted. This
  // prevents e.g. being able to submit forms to the backend before
  // the client side is handling the submit.
  const buttonDisabled = mounted ? disabled || inProgress : true;

  return (
    <button
      ref={ref}
      className={classes}
      {...onOverButtonMaybe}
      {...rest}
      disabled={buttonDisabled}
    >
      {content}
    </button>
  );
});

// Some buttons are link to other pages.
// If enforcePagePreloadFor property is given, lets enhance the Button a bit.
const ButtonWithPagePreload = React.forwardRef((props: any, ref) => {
  const { enforcePagePreloadFor, ...restProps } = props;

  const onOverButtonFn = enforcePreloadOfPage => () => {
    // Enforce preloading of given page (loadable component)
    const { component: Page } = getRoute(enforcePreloadOfPage);
    // Loadable Component has a "preload" function.
    (Page as LoadableComponent<any>).preload?.();
  };

  const onOverButton = enforcePagePreloadFor ? onOverButtonFn(enforcePagePreloadFor) : null;
  const onOverButtonMaybe = onOverButton
    ? {
        onMouseOver: onOverButton,
        onTouchStart: onOverButton,
      }
    : {};

  return <PlainButton {...restProps} {...onOverButtonMaybe} ref={ref} />;
});

const Button: React.FC<{
  rootClassName?: string;
  className?: string;
  spinnerClassName?: string;
  checkmarkClassName?: string;

  inProgress?: boolean;
  ready?: boolean;
  disabled?: boolean;
  enforcePagePreloadFor?: string;

  children?: any;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  title?: string;
}> = React.forwardRef((props, ref) => {
  const { enforcePagePreloadFor, ...restProps } = props;
  return enforcePagePreloadFor ? (
    <ButtonWithPagePreload {...props} ref={ref} />
  ) : (
    <PlainButton {...restProps} ref={ref} />
  );
});

export default Button;

export const PrimaryButton = React.forwardRef((props: any, ref) => {
  const classes = classNames(props.rootClassName || css.primaryButtonRoot);
  return <Button {...props} rootClassName={classes} ref={ref} />;
});
PrimaryButton.displayName = 'PrimaryButton';

export const PrimaryButtonInline = props => {
  const classes = classNames(props.rootClassName || css.primaryButtonInlineRoot);
  return <Button {...props} rootClassName={classes} />;
};
PrimaryButtonInline.displayName = 'PrimaryButtonInline';

export const SecondaryButton = props => {
  const classes = classNames(props.rootClassName || css.secondaryButtonRoot, css.secondaryButton);
  return <Button {...props} rootClassName={classes} />;
};
SecondaryButton.displayName = 'SecondaryButton';

export const SecondaryButtonInline = props => {
  const classes = classNames(
    props.rootClassName || css.secondaryButtonInlineRoot,
    css.secondaryButtonInline
  );
  return <Button {...props} rootClassName={classes} />;
};
SecondaryButton.displayName = 'SecondaryButton';

export const InlineTextButton = props => {
  const classes = classNames(props.rootClassName || css.inlineTextButtonRoot);
  return <Button {...props} rootClassName={classes} />;
};
InlineTextButton.displayName = 'InlineTextButton';

export const SocialLoginButton = props => {
  const classes = classNames(props.rootClassName || css.socialButtonRoot, css.socialButton);
  return <Button {...props} rootClassName={classes} />;
};

SocialLoginButton.displayName = 'SocialLoginButton';
