import { FILTERS_DEFAULTS, filtersQueryParamsSchema } from './SearchPage.filters';
import { ConditionType } from 'config/configListing';
import { useGetBrands } from 'hooks/api/listings/useGetBrands';
import { parseSearchQueryParams, useQueryParams } from 'hooks/useQueryParams';
import {
  categoryOptions,
  departmentOptions,
  productSubtypeOptions,
  productTypeOptions,
} from 'util/productOptions/productTypeDefinitions';

export function useFilterQueryParams() {
  const defaultFilters = FILTERS_DEFAULTS;
  const [params, setParams, generateNewParams] = useQueryParams(
    { ...defaultFilters, text: String(defaultFilters.text) },
    filtersQueryParamsSchema
  );

  return [params, setParams, generateNewParams] as const;
}

export const parseFiltersQueryParams = (search: string) => {
  return parseSearchQueryParams(search, FILTERS_DEFAULTS, filtersQueryParamsSchema);
};

export type GetListingsWithFiltersRequest = {
  brandIds?: number[];
  category?: string;
  sortBy?: string;
  department?: string;
  condition?: string;
  productType?: string[];
  productSubtype?: string[];
  colors?: string[];
  priceRange?: [number, number];
  text?: string;
  sizes?: (string | number)[];
  availability?: 'all' | 'hide_sold';
  preloved?: boolean;
  page?: number;
  perPage?: number;
  country?: string;
  tags?: number[];
};

export type GetListingsRequest = {
  page?: number;
  perPage?: number;
  country?: string;
};

export const useGetSearchPageTitle = () => {
  const [searchParams] = useFilterQueryParams();
  const { data: brands = [] } = useGetBrands();

  const department = searchParams?.department;
  const departmentLabel = department
    ? departmentOptions.find(d => d.value === department)?.label
    : undefined;

  const category = searchParams?.category;
  const categoryLabel = category
    ? categoryOptions.find(pt => pt.value === category)?.label
    : undefined;

  const productTypes = searchParams?.productType || [];
  const productTypeLabels = productTypes
    .map(pt => productTypeOptions.find(opt => opt.value === pt)?.label)
    .filter(Boolean);

  const productSubtypes = searchParams?.productSubtype || [];
  const productSubtypeLabels = productSubtypes
    .map(pst => productSubtypeOptions.find(opt => opt.value === pst)?.label)
    .filter(Boolean);

  const brandIds = searchParams?.brandIds || [];
  const brandLabels = brandIds.map(id => brands.find(b => b.id === id)?.name).filter(Boolean);

  let title = '';
  if (brandLabels.length === 1) {
    title = `${brandLabels[0]} `;
  }
  if (productSubtypeLabels.length === 1) {
    title += `${productSubtypeLabels[0]} `;
  } else if (productTypeLabels.length === 1) {
    title += `${productTypeLabels[0]} `;
  }

  let additionalText = '';
  if (departmentLabel) {
    additionalText += departmentLabel.endsWith('s')
      ? `${departmentLabel}' `
      : `${departmentLabel}'s `;
  }
  if (searchParams?.condition === ConditionType.SampleSale) {
    additionalText += 'Samples ';
  } else if (searchParams?.preloved) {
    additionalText += 'Preloved ';
  }

  if (categoryLabel) {
    additionalText += categoryLabel;
  } else if (searchParams?.condition !== ConditionType.SampleSale) {
    additionalText += 'Items';
  }
  return [title, additionalText]
    .filter(v => Boolean(v.trim()))
    .join(' - ')
    .replace(/\s+/g, ' ')
    .trim();
};
