import { useQuery } from '@tanstack/react-query';

import { get } from 'util/httpsClient';

export type Brand = {
  id: number;
  name: string;
  partnerId: number | null;
};

export const useGetBrands = () => {
  return useQuery({
    queryKey: ['brands'],
    queryFn: () => {
      return get({ path: '/brands' }) as Promise<Brand[]>;
    },
    meta: {
      errorMessage: 'Failed to fetch brand id options',
    },
  });
};
