import { useQuery } from '@tanstack/react-query';

import { get } from 'util/httpsClient';

interface ConfigurationResponse {
  enums: {
    departments: any[];
    categories: any[];
    productTypes: any[];
    productSubtypes: any[];
    colors: any[];
    compositions: any[];
    styleTags: any[];
    sizes: any[];
  };
  brands: {
    id: number;
    name: string;
    partnerId: number | undefined;
  }[];
  defaultPartnerId: number;
  partners: any[];
  sizeMappings: any[];
  collections: {
    id: number;
    name: string;
    filterLink: string;
  }[];
  awsAppConfig: {
    shopDrawer: {
      collections: number[];
      icons: Array<{
        name: string;
        id: string;
      }>;
    };
  };
}

export const useGetConfiguration = () => {
  return useQuery({
    queryKey: getConfigurationQueryKey(),
    queryFn: getConfigurationQueryFn(),
  });
};

export const getConfigurationQueryKey = () => ['configuration'];

export const getConfigurationQueryFn = () => async () =>
  get({ path: '/configuration' }) as Promise<ConfigurationResponse>;

type SEOMetadata = {
  id: number;
  seoTitleText: string;
  seoCollectionText: string;
  seoBodyText: string;
  seoMetaDescription: string | null;
  customData: any;
  urlPathname: string;
};

export const useGetSEOMetadata = () => {
  return useQuery({
    queryKey: getSEOMetadataQueryKey(),
    queryFn: getSEOMetadataQueryFn(),
  });
};

export const getSEOMetadataQueryKey = () => ['seoMetadata'];

export const getSEOMetadataQueryFn = () => async () =>
  get({ path: '/seo-metadata' }) as Promise<SEOMetadata[]>;
