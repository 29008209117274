import * as Dialog from '@radix-ui/react-dialog';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { CSSProperties, useRef } from 'react';

import AddToFavoritesButton from '../../containers/ListingPage/AddToFavoritesButton/AddToFavoritestButton';
import css from './SingleProductGallery.module.css';
import { IconChevronLeft, IconChevronRight, IconClose } from 'assets/icons';
import { IconSpinner } from 'components';
import ImageLazy from 'components/ImageLazy/ImageLazy';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { Listing } from 'models/ListingModels';
import { cn } from 'util/cn';

type SingleProductGalleryProps = {
  currentListing: Listing;
  onOpenModal?: () => void;
  photosFilter?: 'seller' | 'original';
  inModal?: boolean;
};

const SingleProductGalleryMobile: React.FC<SingleProductGalleryProps> = props => {
  const { currentListing, onOpenModal, photosFilter, inModal } = props;

  let images = currentListing.images;
  if (photosFilter === 'seller') {
    images = images.filter(image => !currentListing.originalPhotoIds?.includes(image.id));
  } else if (photosFilter === 'original') {
    images = images.filter(image => currentListing.originalPhotoIds?.includes(image.id));
  }

  const imageAspectRatios = useQuery<unknown, unknown, number[]>({
    enabled: Boolean(currentListing?.id && images?.length > 0),
    queryKey: ['imageAspectRatios', currentListing.id],
    queryFn: () => {
      return Promise.all(
        images.map(image => {
          return new Promise(resolve => {
            const blurUrl = image.variants?.blur?.url;
            if (!blurUrl) {
              resolve(0);
              return;
            }
            const blurImage = new Image();
            blurImage.src = blurUrl;
            blurImage.onload = () => {
              resolve(blurImage.width / blurImage.height);
            };
          });
        })
      );
    },
  });

  return (
    <div className="isolate relative overflow-hidden">
      <div
        className={cn(
          'flex gap-2 px-2 scroll-snap-x items-center scroll-snap-mandatory overflow-x-scroll h-[var(--height)]'
        )}
        style={
          {
            '--height': inModal
              ? 'calc(100vh - 128px)'
              : 'min(450px, calc(100vh - var(--topbarHeight) - var(--mobileBottomNavHeight)))',
          } as CSSProperties
        }
      >
        {imageAspectRatios.isLoading ? (
          <div className="flex-shrink-0 h-full w-full grid place-items-center">
            <IconSpinner />
          </div>
        ) : (
          images.map((image, index) => {
            const aspectRatio = imageAspectRatios.data?.[index];
            const isWide = aspectRatio && aspectRatio > 1;
            return (
              <div
                className={cn(
                  'flex-shrink-0 h-full overflow-hidden grid place-items-center max-w-[150vw]',
                  {
                    'min-w-[70vw]': isWide,
                    'h-full': !isWide,
                  }
                )}
              >
                <ImageWrapper
                  key={image.id}
                  image={image}
                  onOpenModal={onOpenModal}
                  className={cn('max-h-[calc(var(--height))] w-full rounded-lg', {
                    'max-h-[var(--height)]': isWide,
                    'h-full w-auto': !isWide,
                  })}
                />
              </div>
            );
          })
        )}
      </div>
      <AddToFavoritesButton listing={currentListing} />
    </div>
  );
};

export const SingleProductGallery: React.FC<SingleProductGalleryProps> = props => {
  const { currentListing, onOpenModal, photosFilter } = props;

  const sliderRef = useRef<any>(null);
  const sliderImageRef = useRef<any>(null);

  const isMobile = !useMediaQueries({ viewport: 'medium' });
  if (isMobile) {
    return <SingleProductGalleryMobile {...props} />;
  }

  const scrollToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: sliderImageRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  const scrollToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -sliderImageRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  let images = currentListing.images;
  if (photosFilter === 'seller') {
    images = images.filter(image => !currentListing.originalPhotoIds?.includes(image.id));
  } else if (photosFilter === 'original') {
    images = images.filter(image => currentListing.originalPhotoIds?.includes(image.id));
  }

  return (
    <>
      <div className={css.root}>
        <div
          className={classNames(css.imagesWrapper, {
            [css.imagesWrapperSingle]: images.length === 1,
          })}
          ref={sliderRef}
          onClick={onOpenModal}
        >
          {images?.map(image => (
            <ImageWrapper
              key={image.id}
              ref={sliderImageRef}
              image={image}
              onOpenModal={onOpenModal}
            />
          ))}
        </div>
        <AddToFavoritesButton listing={currentListing} />
        {images.length > 1 ? (
          <>
            <button className={css.prevButton} onClick={scrollToPrevSlide}>
              <IconChevronLeft />
            </button>
            <button className={css.nextButton} onClick={scrollToNextSlide}>
              <IconChevronRight />
            </button>
          </>
        ) : null}
      </div>
    </>
  );
};

const ImageWrapper = React.forwardRef<HTMLImageElement, any>((props, ref) => {
  const { image, onOpenModal, className } = props;

  const { srcset, onError } = image;
  const blurSrc = image.variants.blur?.url;

  return (
    <ImageLazy
      onClick={onOpenModal}
      ref={ref}
      srcSet={srcset}
      loading="lazy"
      objectFit="contain"
      blurSrc={blurSrc}
      onError={onError}
      className={className}
    />
  );
});

export const SingleProductGalleryModal: React.FC<
  SingleProductGalleryProps & Pick<Dialog.DialogProps, 'open' | 'onOpenChange'>
> = props => {
  const { open, onOpenChange, ...rest } = props;
  const photosFilter = props.photosFilter;

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={css.dialog__overlay} onClick={() => onOpenChange?.(false)} />
        <Dialog.Content className={css.dialog__content}>
          <div style={{ position: 'absolute', inset: '0' }} onClick={() => onOpenChange?.(false)} />
          <IconClose className={css.dialog__close} onClick={() => onOpenChange?.(false)} />
          {photosFilter &&
            (photosFilter === 'seller' ? (
              <h2 className={css.dialog__title}>Seller photos</h2>
            ) : (
              <h2 className={css.dialog__title}>Original photos</h2>
            ))}
          <SingleProductGallery {...rest} inModal />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default SingleProductGallery;
