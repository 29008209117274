import { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { NamedLink, PrimaryButton } from '../../../components';
import FieldFullDateInput from '../../../components/FieldFullDateInput/FieldFullDateInput';
import { useUpdateCurrentUser } from '../../../hooks/api/users/useUpdateCurrentUser';
import * as validators from '../../../util/validators';
import { Checkbox } from 'components/FieldCheckboxV2/FieldCheckbox';
import { LegalPageTab } from 'containers/LegalPage/LegalPage.constants';
import { formatDate } from 'util/dates';

export function DateOfBirthForm({ showTermsPrompt, onDateOfBirthUpdated }) {
  const intl = useIntl();
  const [agreedToTnc, setAgreedToTnc] = useState(false);
  const { mutateAsync: updateCurrentUser, isLoading } = useUpdateCurrentUser();

  return (
    <div>
      <FinalForm
        onSubmit={async values => {
          await updateCurrentUser({
            publicData: { isDobValid: true },
            privateData: { dateOfBirth: formatDate(values.dateOfBirth) },
          });
          onDateOfBirthUpdated(values);
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{ display: 'grid', gap: 'var(--n-size-2)' }}>
            <FieldFullDateInput
              placeholder="DD/MM/YYYY"
              label={intl.formatMessage({ id: 'SignupForm.dobLabel' })}
              id={'dateOfBirth'}
              name="dateOfBirth"
              type="text"
              validate={validators.composeValidators(
                validators.required(intl.formatMessage({ id: 'SignupForm.dobRequiredMessage' })),
                validators.isValidDate(intl.formatMessage({ id: 'SignupForm.dobInvalidMessage' }))
              )}
            />
            <div className="flex flex-col gap-4">
              {showTermsPrompt && (
                <Checkbox
                  label={
                    <span>
                      By clicking confirm you agree to the{' '}
                      <NamedLink
                        className="underline"
                        name="LegalPage"
                        params={{ tab: LegalPageTab.TermsAndConditions }}
                      >
                        Terms and Conditions
                      </NamedLink>
                    </span>
                  }
                  onCheckedChange={checked => setAgreedToTnc(checked as boolean)}
                />
              )}
              <PrimaryButton
                disabled={showTermsPrompt && !agreedToTnc}
                className="self-end transition-colors"
                type="submit"
                inProgress={isLoading}
              >
                <FormattedMessage id="DateOfBirthModal.dobSubmitButton" />
              </PrimaryButton>
            </div>
          </form>
        )}
      />
    </div>
  );
}
