import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';

import { useGetUserSocials, useGetUserSocialsQueryKey } from './useGetUserSocials';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { post } from 'util/httpsClient';
import { getPath } from 'util/routes';

export const useToggleFollowUser = () => {
  const { currentUser } = useCurrentUser();
  const loggedIn = currentUser.id?.uuid;
  const queryClient = useQueryClient();
  const history = useHistory();
  const { data: userSocials } = useGetUserSocials();

  return useMutation({
    mutationFn: async (userId: string) => {
      if (userId === currentUser.id?.uuid) {
        return;
      }

      if (!loggedIn) {
        return history.push(getPath('LoginPage'));
      }

      const followingUser = userSocials?.followingIds?.find(id => id === userId);

      return post({
        path: `/users/${followingUser ? 'unfollow' : 'follow'}`,
        body: {
          [followingUser ? 'userToUnfollow' : 'userToFollow']: userId,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(useGetUserSocialsQueryKey);
    },
    meta: {
      name: 'toggleFollowUser',
    },
  });
};
