import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useIntercom } from 'react-use-intercom';

import css from './TopbarMobileMenu.module.css';
import { IconIntercomChat, IconMenu } from 'assets/icons';
import { Avatar, NamedLink } from 'components';
import Drawer from 'components/Drawer/Drawer';
import { useHasIntercomCookie } from 'components/IntercomChatLauncher/IntercomChatLauncher';
import { ConditionType } from 'config/configListing';
import { InfoPageTab } from 'containers/InfoPage/InfoPage';
import { ProfileTab } from 'containers/ProfilePage/ProfilePage';
import { SettingsPageTab } from 'containers/SettingsPage/SettingsPage.constants';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useGeolocation } from 'hooks/useGeolocation';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { getCountryCodes, getFlagEmoji } from 'translations/countryCodes';
import { cn } from 'util/cn';
import { DEFAULT_SORTING } from 'util/constants';
import { userDisplayNameAsString } from 'util/data';

const TopbarMobileMenu = ({ isAuthenticated, onLogout }) => {
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQueries({ viewport: 'medium' });
  const { currentUser } = useCurrentUser();
  const displayName = userDisplayNameAsString(currentUser, 'USER')?.split(' ')[0];
  const { show: showIntercom } = useIntercom();
  const intl = useIntl();
  const { userGeolocation, updateGeolocation } = useGeolocation();
  const [countryCodes, setCountryCodes] = useState<{ code: string; name: any }[]>([]);

  useEffect(() => {
    const codes = getCountryCodes(intl.locale, 'all', null);
    setCountryCodes(codes);
  }, [intl.locale]);

  useEffect(() => {
    if (isDesktop) {
      setOpen(false);
    }
  }, [isDesktop]);

  const hasIntercomCookie = useHasIntercomCookie();
  const isOpen = open && !isDesktop;

  const handleCountryChange = event => {
    const newCountry = event.target.value;
    updateGeolocation(newCountry);
  };

  return (
    <>
      <button onClick={() => setOpen(true)} className="p-2">
        <IconMenu />
      </button>
      <Drawer
        isOpen={isOpen}
        onOpenChange={setOpen}
        contentClassName={cn('grid gap-3 grid-rows-[auto_auto_1fr]', css.topbar)}
      >
        <div
          className={classNames('grid gap-x-2 gap-y-1', {
            'grid-cols-[auto,1fr] grid-rows-[auto,auto] items-center': isAuthenticated,
          })}
        >
          {isAuthenticated && <Avatar className="row-span-2 !w-8 !h-8" user={currentUser} />}
          <div className="font-syne font-bold uppercase">
            HEY, {isAuthenticated ? displayName : 'Nolder'}
          </div>
          {isAuthenticated ? (
            <button
              onClick={() => {
                onLogout();
                setOpen(false);
              }}
              className="justify-self-start font-syne text-00 font-bold uppercase underline"
            >
              Log out
            </button>
          ) : (
            <div className="font-syne font-bold uppercase">
              <NamedLink name="LoginPage" onClick={() => setOpen(false)} className="underline">
                Log in
              </NamedLink>
              {' or '}
              <NamedLink name="SignupPage" onClick={() => setOpen(false)} className="underline">
                Sign up
              </NamedLink>
            </div>
          )}
        </div>
        <div className="grid font-syne content-start font-bold uppercase">
          <NamedLink
            name="ResellLandingPage"
            onClick={() => setOpen(false)}
            className="py-2 border-b border-gray-200"
          >
            SELL
          </NamedLink>
          <NamedLink
            name="SearchPage"
            onClick={() => setOpen(false)}
            className="py-2 border-b border-gray-200"
          >
            SHOP ALL
          </NamedLink>
          {/* <NamedLink
            name="SearchPage"
            to={{
              search: new URLSearchParams({
                condition: ConditionType.SampleSale,
                sortBy: DEFAULT_SORTING,
              }).toString(),
            }}
            onClick={() => setOpen(false)}
            className="py-2 border-b border-gray-200"
          >
            SAMPLES
          </NamedLink> */}
          {/* <NamedLink
            name="SearchPage"
            to={{ search: new URLSearchParams({ preloved: String(true) }).toString() }}
            onClick={() => setOpen(false)}
            className="py-2 border-b border-gray-200"
          >
            PRE-LOVED
          </NamedLink> */}
          {/* <a
            href="https://brands.thenold.com"
            target="_blank"
            rel="noreferrer"
            className="py-2 border-b border-gray-200"
          >
            BECOME A BRAND PARTNER
          </a> */}
        </div>
        {isAuthenticated && (
          <div className="grid font-syne content-end font-bold uppercase mt-4">
            <div className="font-montserrat text-00 font-normal uppercase">Your profile</div>
            <NamedLink
              name="SettingsPage"
              params={{ tab: SettingsPageTab.EditProfile }}
              onClick={() => setOpen(false)}
              className="py-2 border-b border-gray-200"
            >
              Profile Settings
            </NamedLink>
            <NamedLink
              name="MyProfilePage"
              onClick={() => setOpen(false)}
              className="py-2 border-b border-gray-200"
            >
              My Nold Profile
            </NamedLink>
            <NamedLink
              name="MyProfilePage"
              to={{ search: `profileTab=${ProfileTab.FAVOURITES}` }}
              onClick={() => setOpen(false)}
              className="py-2 border-b border-gray-200"
            >
              Wishlist
            </NamedLink>
            <NamedLink
              name="WalletPage"
              onClick={() => setOpen(false)}
              className="py-2 border-b border-gray-200"
            >
              Wallet
            </NamedLink>
            <NamedLink
              name="MyProfilePage"
              to={{ search: `profileTab=${ProfileTab.ORDERS}` }}
              onClick={() => setOpen(false)}
              className="py-2 border-b border-gray-200"
            >
              My Orders
            </NamedLink>
            <NamedLink
              name="InfoPage"
              params={{ tab: InfoPageTab.HowItWorks }}
              onClick={() => setOpen(false)}
              className="py-2 border-b border-gray-200"
            >
              How it works
            </NamedLink>
          </div>
        )}
        <div className="mt-auto flex flex-col gap-4 pb-4">
          <div className="relative">
            <select
              id="mobile-country-select"
              value={userGeolocation}
              onChange={handleCountryChange}
              className="font-syne font-bold"
            >
              {countryCodes.map(country => (
                <option key={country.code} value={country.code}>
                  {getFlagEmoji(country.code)} {country.name}
                </option>
              ))}
            </select>
          </div>
          {hasIntercomCookie ? (
            <button
              onClick={showIntercom}
              className="flex items-center py-2 gap-1 font-syne font-bold "
            >
              <IconIntercomChat /> Chat with us
            </button>
          ) : (
            <a
              href="mailto:hello@thenold.com"
              className="flex items-center gap-1 py-2 font-syne font-bold "
            >
              <IconIntercomChat /> Chat with us
            </a>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default TopbarMobileMenu;
